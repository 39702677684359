import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Seo } from "../components/seo";
import Layout from "../components/layout";
import HireBanner from "../components/hire/HireBanner";
import OurWorkslef from "../components/hire/OurWorkself";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Faq from "../components/Faq";
import { Helmet } from "react-helmet";

const HirePage = () => {
  const banner = {
    title: `Mobile App <span class='h1-span'>Design Agency</span>`,
    text: "Best Mobile App Design",
    content: "Our mobile app design agency prioritizes user experience and functionality. With our comprehensive app design services, we create visually appealing, user-friendly, and intuitive apps that engage and retain users in your business.",
    list: [
      'Proven Design Expertise',
      'User-Centric Approach',
      'Conversion-Driven Designs'
    ]
  };
  const ourData = {
    title: "Client Insight On Our App Design Agency",
    list1: [
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'Octet has been very supportive to our design and technical requirements. Their designers and developers have always helped us to deliver creative and on-time solutions to our marketing needs.',
        name: 'Mariam Khan',
        des: 'Head Of Digital Marketing'
      },
      {
        logo: ['adani.webp'],
        stars: 5,
        text: "Octet Designers were instrumental in transforming our internal products and processes.",
        name: 'Naveen Kumar',
        des: 'QA Manager'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: "The team's flexibility and understanding of our business needs impressed us with their fast turnaround.",
        name: 'Ishan',
        des: 'Group Product Manager'
      }
    ],
    list2: [
      {
        logo: ['visa.webp'],
        stars: 5,
        text: 'With so many things we could be doing in our product, it was hard to streamline and decide where to invest our time and effort.',
        name: 'Vipin D',
        des: 'Customer Success Manager'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: 'Using Design first approach with Octet’s designers gave us more clarity and streamlined our solution and delivery.',
        name: 'Todd Baughman',
        des: 'SVP Global Operations'
      },
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'They have delivered more than what they have committed. They made our sophisticated platform super simple and classic, our customers love using our app than any competitors.',
        name: 'Parag Chivate',
        des: 'Founder and CEO'
      },
    ]
  };
  const commonContent = {
    title: "Maximize Engagement With <span class='h1-span'>Mobile App Design Services</span>",
    content: [
      {
        title: "150+ Mobile Apps Designed",
        text: "With over 150+ mobile apps designed, we are experts at crafting highly performing, intuitive, custom mobile solutions that maximize user engagement and conversions.",
      },
      {
        title: "250+ Features Integrated",
        text: "We have integrated over 250 custom features across mobile applications, enhancing functionality and providing unique user experiences catering to specific businesses.",
      },
      {
        title: "15+ Experienced Designers",
        text: "With a diverse team of 15+ experienced designers, we bring deep expertise and a holistic user-centric approach to meet custom mobile app design requirements.",
      },
      {
        title: "100+ Satisfied Clients",
        text: "With a track record of serving 100+ clients across industries with our app design services, we assist businesses in enhancing user engagement, increasing brand loyalty, and boosting revenue.",
      },
    ],
  };
  const commonContent1 = {
    title: "Experience Difference With <span class='h1-span'>Mobile App Design Company</span>",
    content: [
      {
        title: "Proven Expertise",
        text: "With years of experience in mobile app design, our team of skilled designers crafts visually stunning and highly functional apps that cater to your business needs.",
      },
      {
        title: "User-Centric Approach",
        text: "Our app design agency prioritizes user experience. We ensure your mobile app is intuitive and tailored to users' preferences, increasing user retention and satisfaction.",
      },
      {
        title: "Adaptive Designs",
        text: "With our app design services, we create responsive and adaptive designs that adapt to various screen sizes and device types, ensuring a consistent user experience.",
      },
      {
        title: "Scalable Solutions",
        text: "Whether a startup or an established enterprise, we develop scalable mobile solutions to accommodate your evolving requirements, ensuring your app's longevity and adaptability.",
      },
      {
        title: "Data-Driven Optimizations",
        text: "Our mobile app design agency emphasizes data-driven decision-making in the mobile app design process and leverages user analytics to refine and optimize mobile design. ",
      },
      {
        title: "Accelerated Time-to-Market",
        text: "With our agile methodologies and streamlined design workflows, we can expedite the mobile app development process to bring your innovative ideas to market faster.",
      },
    ],
  };
  const Questions = [
    "What app design services do you offer?",
    "How long does your mobile app design process take?",
    "Why should I choose Octet for Mobile App Design?",
    "How much do your mobile app design services cost?",
    "How can I get started with your app design agency?"
  ];
  const faqData = [
    {
      para: [
        `We offer comprehensive app design services, including UI UX design, conceptualization, wireframing, prototyping, visual design, responsive design, user testing, iterative design, accessibility design, design documentation, and collaboration and communication throughout the mobile app design process.`,
      ],
    },
    {
      para: [
        `The duration of our mobile app design process varies depending on various factors, such as your project's complexity, features, and custom requirements.`,
        `The process, from initial concept to final design delivery, typically takes a few weeks to several months.`
      ],
    },
    {
      para: [
        `At Octet, we are committed to excellence, innovation, and client satisfaction. Our mobile app design services prioritize user-centric design, cutting-edge techniques, and a collaborative approach to deliver outstanding results that align with your business goals and exceed your expectations.`,
      ],
    },
    {
      para: [
        `The cost of our application design services depends on project scope, complexity, and timelines.`,
        `We provide customized quotes tailored to your specific requirements. <a href="/contact-us/" title="contact us">Contact us</a> to discuss your project, and we'll give you a detailed estimate.`
      ],
    },
    {
        para: [
          `You can contact us by filling out the <a href="/contact-us/" title="contact us">Contact us</a> form or directly <a href="https://calendly.com/sajan_deokar/30min" target="_blank" title="scheduling a meeting">scheduling a meeting</a>. We'll then discuss your project ideas, goals, and requirements.`,
          `From there, we'll guide you through the design process, ensuring a smooth and successful collaboration.`
        ],
      },
  ];
  return (
    <>
    <Helmet>
      <script type="application/ld+json">
        {`
         {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "What app design services do you offer?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "We offer comprehensive app design services, including UI UX design, conceptualization, wireframing, prototyping, visual design, responsive design, user testing, iterative design, accessibility design, design documentation, and collaboration and communication throughout the mobile app design process."
              }
            },{
              "@type": "Question",
              "name": "How long does your mobile app design process take?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "The duration of our mobile app design process varies depending on various factors, such as your project's complexity, features, and custom requirements. 
          
          The process, from initial concept to final design delivery, typically takes a few weeks to several months."
              }
            },{
              "@type": "Question",
              "name": "Why should I choose Octet for Mobile App Design?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "At Octet, we are committed to excellence, innovation, and client satisfaction. Our mobile app design services prioritize user-centric design, cutting-edge techniques, and a collaborative approach to deliver outstanding results that align with your business goals and exceed your expectations."
              }
            },{
              "@type": "Question",
              "name": "How much do your mobile app design services cost?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "The cost of our application design services depends on factors such as project scope, complexity, and timelines. 
          
          We provide customized quotes tailored to your specific requirements. Contact us to discuss your project, and we'll give you a detailed estimate."
              }
            },{
              "@type": "Question",
              "name": "How can I get started with your app design agency?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "You can reach out to us by filling out the Contact Us form or directly scheduling a meeting. We'll then discuss your project ideas, goals, and requirements. 
          
          From there, we'll guide you through the design process, ensuring a smooth and successful collaboration."
              }
            }]
          }                                              
        `}
      </script>
    </Helmet>
    <Layout hire="Mobile App Design Agency" hotjarHire={true} footerText="Maximize your conversions with Us">
      <div>
        <HireBanner data={banner} hire pageName="Mobile App Design Agency" />
        <section className="py-[40px]">
          <div className="marquee lg:h-[100px] md:h-[80px] h-[60px]">
            <div className="lg:h-[100px] md:h-[80px] h-[60px]">
              <div className="flex items-center">
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                </div>
              </div>
              <div className="flex items-center">
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <OurWorkslef data={ourData} hire />
        <CommonServiceComponent data={commonContent} mode={"dark"} hire />
        <CommonServiceComponent data={commonContent1} mode={"light"} hire />
        <Faq grey section Questions={Questions} faqData={faqData} />
      </div>
    </Layout>
    </>
  );
};

export default HirePage;

export const Head = () => (
  <Seo
    title="Mobile App Design Agency | Mobile App Design"
    description="Looking for a mobile app design agency? Our mobile app design services offer innovative, user-friendly solutions to bring your ideas to life. Contact us today!"
  />
);
